import { Box } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { decodeAddress, encodeAddress } from '@polkadot/keyring';
import { hexToU8a, isHex } from '@polkadot/util';

export const isValidSubstrateAddress = (address: string) => {
    try {
        encodeAddress(
            isHex(address)
                ? hexToU8a(address)
                : decodeAddress(address)
        );

        return true;
    } catch (error) {
        return false;
    }
};

export default function Search() {
  const { id } = useParams() as {[key: string]: string}
  if (isValidSubstrateAddress(id)) {
    window.open(`/account/${id}`, "_self")
  } else if (id.indexOf("-") >= 0) {
    window.open(`/extrinsic/${id}`, "_self")
  } else if (!isNaN(parseInt(id))) {
    window.open(`/block/${id}`, "_self")
  }
  return (
    <Box>
      404 Error: Not Found
    </Box>
  )
}
