import { decodeAddress, encodeAddress } from "@polkadot/keyring";
import { Box, Heading } from "@chakra-ui/react";
import Tabs from "../components/Tabs"
import ExtrinsicTable from "./ExtrinsicTable";
import TransferTable from "./TransferTable";
import MeritTable from "./MeritTable";
import { useEffect, useState } from "react";
import { ApiPromise, WsProvider } from "@polkadot/api";
import { RPC_ENDPOINT } from "../utils";
import { formatBalance } from "@polkadot/util";
import Overview, { Data as OverviewData } from "../components/Overview";


export default function AccountData({id}: Props) {
  id = encodeAddress(decodeAddress(id))
  const [balance, setBalance] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      debugger;
      const provider = new WsProvider(RPC_ENDPOINT);
      const api = await ApiPromise.create({ provider });
      const balances = await api.derive.balances.account(id);
      setBalance(formatBalance(balances.freeBalance, {
        decimals: 12,
        withSi: false,
        forceUnit: "LLD",
        withUnit: true,
        locale: "en-US",
      }));
    })();
  }, [balance, setBalance, id])
  const extrinsicFilter = {"signerId": {"equalTo": id}}
  const transferFilter = {
    "or": [
      {
        "fromId": {
          "equalTo": id
        }
      },
      {
        "toId": {
          "equalTo": id
        }
      }
    ]
  }
  const tabsData = [
    {label: "Extrinsics", content: <ExtrinsicTable moreVariables={{filter: extrinsicFilter}}/>},
    {label: "LLD Transfers", content: <TransferTable moreVariables={{filter: transferFilter}}/>},  
    {label: "LLM Transfers", content: <MeritTable moreVariables={{filter: transferFilter}}/>},  
  ]

  let overviewData: OverviewData = [
    {
      label: "LLD Balance",
      value: balance ? `${balance.toString()} LLD` : "Fetching..."
    }
  ];

  return(
    <Box>
      <Heading>Account {id}</Heading>
      <Overview data={overviewData} />
      <br />
      <Tabs data={tabsData} />
    </Box>
  )
}

interface Props {
  id: string
}
