import { gql, useQuery } from "@apollo/client";
import { Box, Heading } from "@chakra-ui/react";
import Overview, {Data as OverviewData} from "../components/Overview";
import { FullAccountLink, BlockLink, ExtrinsicLink } from "../utils";


export default function LLMTransferData({id}: Props) {
  const query = gql`
    query Merit($transferId: String!) {
      query {
        merit(id: $transferId) {
          id
          fromId
          toId
          value
          block {
            id,
            timestamp
          }
          extrinsicIndex
        }
      }
    }
  `
  const variables = {
    "transferId": id
  }

  const { data } = useQuery(query, {variables: variables});
  let overviewData: OverviewData = []
  if (data) {
    const transfer = data.query.merit
    overviewData = [
      {label: "Timestamp (UTC)", value: transfer.block.timestamp},
      {label: "Block", value: BlockLink(transfer.block.id)},
      {label: "Extrinsic", value: ExtrinsicLink(`${transfer.block.id}-${transfer.extrinsicIndex}`)},
      {label: "Sender", value: FullAccountLink(transfer.fromId)},
      {label: "Recipient", value: FullAccountLink(transfer.toId)},
      {label: "Amount (LLM)", value: (BigInt(transfer.value)/(BigInt(10**12))).toString()},
    ]
  }

  return(
    <Box>
      <Heading>LLM Transfer #{id}</Heading>
      <br />
      <Overview data={overviewData} />
    </Box>
  )
}

interface Props {
  id: string
}
