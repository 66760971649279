import { gql, useQuery } from "@apollo/client";
import { PAGINATION_PART_OF_QUERY, timeSince, BlockLink, ExtrinsicLink, LLDTransferLink, LLMTransferLink } from "../utils";
import RTable from "./RTable";

function eventLink(d: EventData) {
  if (d.section === "balances" && d.method === "Transfer") {
    return LLDTransferLink(d.id);
  }
  
  if (d.section === "assets" && d.method === "Transferred" && BigInt(d.data[0]) === BigInt(1)) {
    return LLMTransferLink(d.id);
  }

  return d.id;
}

export default function EventTable({moreVariables, noMore}: Props) {
  const query = gql`
    query Events($first: Int, $orderBy: [EventsOrderBy!], $filter: EventFilter, $after: Cursor) {
      query {
        events(first: $first, orderBy: $orderBy, filter: $filter, after: $after) {
          ${PAGINATION_PART_OF_QUERY}
          nodes {
            id
            section
            method
            extrinsicId
            data
            block {
              id
              number
              timestamp
            }
          }
        }
      }
    }
  `
  const variables = {
    "first": 20,
    "orderBy": ["BLOCK_NUMBER_DESC", "INDEX_DESC"],
    ...moreVariables
  }

  const columns = [
    { Header: "Event Id", accessor: "id" },
    { Header: "Block", accessor: "block" },
    { Header: "Extrinsic Id", accessor: "extrinsicId" },
    { Header: "Time", accessor: "time" },
    { Header: "Action", accessor: "action" }
  ];
  const { loading, data, fetchMore } = useQuery(query, {variables: variables});

  const skipArgsMethods = ["ExtrinsicFailed", "ExtrinsicSuccess"];
  const rData =
    data &&
    data.query.events.nodes.map(
      (d: EventData) => ({
        id: eventLink(d),
        block: BlockLink(d.block.id),
        extrinsicId: d.extrinsicId && ExtrinsicLink(d.extrinsicId),
        time: timeSince(d.block.timestamp),
        action: skipArgsMethods.includes(d.method) ? `${d.section}.${d.method}` : `${d.section}.${d.method}(${d.data.join(", ")})`
      })
    );
  
  const loadMore = () => {
    fetchMore({
      variables: {after: data.query.events.pageInfo.endCursor}
    })
    console.log(`Load more after ${data.query.events.pageInfo.endCursor}`)
  }

  return (
    <RTable  {...{data, rData, columns, loadMore, loading, noMore, hasNextPage: data?.query.events.pageInfo.hasNextPage }}/>
  )
}

interface EventData {
  id: string
  section: string
  method: string
  extrinsicId?: string
  data: string[]
  block: {
    id: string
    number: number
    timestamp: string
  }
}

interface Props {
  noMore?: boolean
  moreVariables?: {
    first?: number
    after?: string
    filter?: any
    orderBy?: any
  }
}
