import { gql, useQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import { Redirect, useParams } from "react-router-dom";
import ExtrinsicData from "../data/ExtrinsicData";
import ExtrinsicTable from "../data/ExtrinsicTable";

export function ExtrinsicById({ id }: { id: string }) {
  return (
    <Box>
      <ExtrinsicData id={id}/>
    </Box>
  )
}

export function ExtrinsicsByHash({ hash }: { hash: string }) {
  const query = gql`
    query Extrinsics($extrinsicHash: String!, $orderBy: [ExtrinsicsOrderBy!]) {
      query {
        extrinsics(orderBy: $orderBy, filter: { hash: { equalTo: $extrinsicHash } }) {
          nodes {
            id
          }
        }
      }
    }
  `
  const variables = {
    "orderBy": ["BLOCK_NUMBER_DESC", "INDEX_DESC"],
    "extrinsicHash": hash
  }

  const { data } = useQuery(query, {variables: variables});
  if (!data) {
    return <Box>Loading...</Box>;
  }
  if (data.query.extrinsics.nodes.length === 0) {
    return <Box>Extrinsic not found. Maybe not finalized yet?</Box>
  }
  if (data.query.extrinsics.nodes.length === 1) {
    return <Redirect to={`/extrinsic/${data.query.extrinsics.nodes[0].id}`} />;
  }

  return (
    <Box>
      <ExtrinsicTable moreVariables={{filter: { hash: { equalTo: hash }}}}/>
    </Box>
  )
}

export default function Extrinsic() {
  const { id } = useParams() as {[key: string]: string}
  if (id.includes("-")) {
    return <ExtrinsicById id={id} />
  } else {
    return <ExtrinsicsByHash hash={id} />
  }
}
