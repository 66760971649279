import { Box, Heading } from "@chakra-ui/react";
import MeritTable from "../data/MeritTable";

export default function Merits() {
  return (
    <Box>
      <Heading>
        LLM Transfers
      </Heading>
      <MeritTable />
    </Box>
  )
}
