import { Box, Heading } from "@chakra-ui/react";
import ExtrinsicTable from "../data/ExtrinsicTable";

export default function Extrinsics() {
  return (
    <Box>
      <Heading>
        Extrinsics
      </Heading>
      <ExtrinsicTable moreVariables={{
        filter: {
          section: {
            notIn: ["authorship", "timestamp", "electionProviderMultiPhase"],
          }
        }
      }}/>
    </Box>
  )
}
